import React, { useEffect } from "react";
import { navigate } from "gatsby";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { toast } from "react-toastify";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
const ridePaymentStatus = gql`
  query($id: String!) {
    ride(id: $id) {
      id
      paymentStatus
      paymentTime
      paymentType
    }
  }
`;

const updatePaymentTime = gql`
  mutation($id: ID!, $paymentTime: String!) {
    paymentTime(id: $id, paymentTime: $paymentTime) {
      id
    }
  }
`;

var requested = false;
const PaymentProcess = ({ location }) => {
  console.log("locaton : ", location);
  const { search } = location;
  const id = search.substr(4);
  console.log("id: ", id);
  const { loading, data, error } = useQuery(ridePaymentStatus, {
    variables: {
      id: id,
    },
  });

  const [
    updateTime,
    { data: cancelResp, loading: cancelLoad, error: cancelError },
  ] = useMutation(updatePaymentTime);

  const onPaymentUpdate = async () => {
    if (requested) return;
    requested = true;
    console.log(
      "%c updating paymnet time.......",
      "color: white; font-size: 20px; background:green;"
    );
    let res = await updateTime({
      variables: {
        id,
        paymentTime: new Date(),
      },
    });
    toast.success("Payment Succesfully", { autoClose: 1200 });
    sessionStorage.removeItem("@paymentConfirmation");
    localStorage.removeItem("XXXX");
    navigate("/thankyou");
    console.log("res : ", res);
  };

  if (error) {
    console.log("graphql error message", error);
    toast.error("Payment Failed Please Try Again", { autoClose: 1200 });
    navigate("/payment", {
      state: {
        rideId: id,
      },
    });
  }

  if (data) {
    const { paymentStatus, paymentTime, paymentType } = data.ride;
    console.log("payment status ", paymentStatus);

    if (paymentStatus === "paid" || paymentType === "cash") {
      if (!paymentTime) {
        onPaymentUpdate();
      } else {
        toast.success("Payment Succesfully", { autoClose: 1200 });
        navigate("/thankyou");
      }
    } else {
      toast.error("Payment Failed Please Try Again", { autoClose: 1200 });
      navigate("/payment", {
        state: {
          rideId: id,
        },
      });
    }
  }
  return (
    <Layout>
      <SEO title="Payment Processing" />
      <div className="spinner"></div>
    </Layout>
  );
};

export default PaymentProcess;
